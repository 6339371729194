// Here you can add other styles
@media print {
  body * {
    visibility: hidden;
  }

  /* Hide Save as PDF option */
  @page {
    size: auto; /* Reset page size to its default */
    margin: 10mm; /* Reset margins to their default */
  }

  /* You can also hide other elements as needed */
  /* For example, hide page numbers and URLs */
  .page-numbers,
  .url {
    display: none !important;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    top: -125px;
    left: 0px;
    position: absolute;
  }

  .table-print {
    border: 1px solid #000;
    padding: 20px;
    font-size: larger;
  }

  .title {
    border: 1px solid #000;
  }
}

.table-print {
  border: 1px solid #000;
  padding: 20px;
  font-size: larger;
}

.title {
  border: 1px solid #000;
}

.list {
  user-select: none;
  display: flex;
  justify-content: flex-start;
}

.item {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  margin: 8px;
  cursor: grab;
}
